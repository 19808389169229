import UserCourses from "../UserHomepage/userCourses.component";
import { CourseContainer } from "../UserHomepage/userHome.styles";
import Auth from '../../Helpers/auth';
import { useState } from "react";
import { MobileCoursesContainer, BreadContainer } from "./MoblileCourses.styles";
import BreadCrumbs from "../../Components/Breadcrumbs/breadcrumbs.component";

const MobileCourses = () => {
    const {data} = Auth.getProfile();
    const [chosen, setChosen] = useState('AllCourses');
    
    return ( 
        <MobileCoursesContainer>
            <BreadContainer>
                <BreadCrumbs one='Home' oneLink='/user' two='User Courses' />
             </BreadContainer>
        <h1>Your Courses</h1>
        <CourseContainer>
                    <UserCourses user={data} chosen={chosen}/>
        </CourseContainer>
        </MobileCoursesContainer>
     );
}
 
export default MobileCourses;