import SecondaryButton from "../../Components/Buttons/secondaryButton.component";
import { ArrayContainer } from "./FormElements.styles";
const ArrayInput = ({label, single, arrayItems, setSingle, setArray}) => {

    return (  
        <ArrayContainer>
            <label type='text'>{label}</label>
            <div className="mappedElements">
            {arrayItems?.map((i) => {
                return <div style={{display: 'flex', justifyContent: 'space-between'}}>
                     <p> - {i}</p>
                     <SecondaryButton functionName={(e) => {
                        e.preventDefault();
                        const filtered = arrayItems.filter((item) => {
                            return item !== i
                        });
                        setArray(filtered)
                     }} span="Delete" />
                     </div>
            }
            
            )}
            </div>
       <div className="inputDiv">
       <input value={single} onChange={(e) => setSingle(e.target.value)}/>
       <SecondaryButton functionName={(e) => {
            e.preventDefault();
            setArray([...arrayItems, single]);
            setSingle('');
        }} span={'Add '+ label} />
       </div>
        
        
        </ArrayContainer>
    );
}
 
export default ArrayInput;