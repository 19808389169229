import { NewBurger } from "./burger.styles";

const Burger = ({open, setOpen}) => {
    return (
      <NewBurger open={open} onClick={() => setOpen(!open)} >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="icon icon-menu nav-icon primary">
    <path className="secondary" fill-rule="evenodd"
        d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
    </svg>
      </NewBurger>
    // <StyledBurger open={open} onClick={() => setOpen(!open)}>
    //   <div />
    //   <div />
    //   <div />
    // </StyledBurger>
     );
}
 
export default Burger;