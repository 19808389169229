import { useState, useEffect } from "react";
import { httpGetSingleCourse } from "../Hooks/course.hooks";
import { useParams } from "react-router-dom";
import { BreadcrumbContainer, SingleCourseContainer, SingleCardContainer, SingleCourseImage, SingleCourseInfo, SingleTop, SingleBottom, SingleButton } from "./EachCourse.styles";
import { Cloudinary } from "@cloudinary/url-gen";
import BreadCrumbs from "../Components/Breadcrumbs/breadcrumbs.component";
//import { useNavigate } from "react-router-dom";
import BuyButton from "../BuyCourse/BuyButton/buyButton.component";
// import DisplayPresentation from "../AdminRoutes/CreateRoutes/CreateCourse/DisplayPresentation.component";
//import pdf from '../Files/Example.pdf'

const SingleCourse = () => {
    //const navigate = useNavigate();
    const {id} = useParams();
    const [course, setCourse] = useState();
    const [img, setImg] = useState();

    useEffect(() => {
        const cloud = new Cloudinary({cloud: {cloudName: 'dteb3vc5h'}});
        const FindCourse = async() => {
            if(id){
                let course = await httpGetSingleCourse(id);
                setCourse(course);
                const myImage = cloud.image(course.image?.publicId).toURL();
                setImg(myImage)
               }
        };
        FindCourse()
    }, [id]);

    return ( 
       <SingleCourseContainer>
        <BreadcrumbContainer>
        <BreadCrumbs one="courses" oneLink='/online' two={course?.courseName}/>
        </BreadcrumbContainer>
        <SingleCardContainer>
        <SingleTop>
        <SingleCourseImage>
        <img src={img} alt="course"/>
        </SingleCourseImage>
        <SingleCourseInfo>
            <div className="courseTop">
            <h1>{course?.courseName}</h1>
            <h2>${course?.price}</h2>
            </div>
            <div className="courseDescription">
            <h4>Course Details</h4>
            <p>{course?.description}</p>
            </div>
            <div className="courseMiddle">
            <div className="industries">
                <h5>Industries:</h5>
                <ul style={{paddingLeft: '2rem', listStyleType: 'square'}}>
                {course?.tags.map((t) => <li>{t}</li>)}
                </ul>
           </div>
           <h5>Expires In: <span style={{fontWeight: 'normal'}} >{course?.expiryDate} years</span></h5>

            </div>
            <SingleButton>

                <BuyButton />
        </SingleButton>
        </SingleCourseInfo>
        </SingleTop>
        <SingleBottom>
        
        </SingleBottom>
        {/* <iframe title="pdf" src={pdf}
   width="800" height="600"></iframe> */}
   {/* Doing a presentation with a pdf is not available for free tier */}
        {/* <DisplayPresentation publicId={course?.pdf.publicId}/> */}
        </SingleCardContainer>
       </SingleCourseContainer>
     );
}
 
export default SingleCourse;