import styled from 'styled-components';

export const OuterContainer = styled.main`
    display: flex;
    width: 100%;
    @media screen and (width <= 900px){
        flex-direction: column;
    }
`;

export const LeftContainer = styled.aside`
    width: 30%;
    padding: 1rem;
    background-color: ${({theme}) => theme.colors.grey5};
    display: flex;
    flex-direction: column;
    h3{
        color: ${({theme}) => theme.colors.offWhite};
        font-size: 2.7rem;
        font-weight: 350;
        border: 1px solid orange;
    }
    //border: 1px solid orange;
    @media screen and (width <= 900px){
        width: 100%;
        height: 15vh;
        h3{
        font-size: 2.2rem;
    }
    }
    @media screen and (width <= 600px){
        h3{
            font-size: 1.6rem;
        }
        height: 10%;
    }
`;
export const UserInfo = styled.div`
    border-bottom: 1px solid ${({theme}) => theme.colors.yellow2};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1rem 0 2rem 1rem;
    img{
        border-radius: 50%;
        width: 125px;
        height: 125px;
    }
    //border: 1px solid blue;
    @media screen and (width <= 900px){
        width: 100%;
        height: 50%;
        flex-direction: row;
        img{
        width: 50px;
        height: 50px;
    }
    }
    @media screen and (width <= 600px){
        //padding: 1rem 0 2rem 1rem;
        border-bottom: none;
        padding: 0;
        img{
        width: 35px;
        height: 35px;
    }
    }
`;
export const UserLinks = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
    height: 50%;
    padding-left: 1rem;
   // border: 1px solid green;
  
    @media screen and (width <= 900px){
        width: 100%;
        height: 50%;
        flex-direction: row;
        a{
            margin-right: 1rem;
        }
    }
    @media screen and (width <= 600px){
       display: none;
    }
`;

export const RightContainer = styled.section`
    width: 70%;
    background-color: ${({theme}) => theme.colors.grey};
    padding: 1rem;
    overflow-y: scroll;
    //border: 1px solid deeppink;
    @media screen and (width <= 900px){
        width: 100%;
       height: 90vh;
    }
    @media screen and (width <= 600px){
       display: none;
    }
`;
export const MobileLinksContainer = styled.section`
    padding-top: 1rem;
    @media screen and (width >= 600px){
       display: none;
    }
    @media screen and (width <= 600px){
       display: flex;
       background-color: ${({theme}) => theme.colors.grey};
       height: 100vh;
       flex-direction: column;
       align-items: center;
       gap: 1rem;
    }
`;
export const EachLink = styled.div`
    width: 80%;
    background-color: ${({theme}) => theme.colors.offWhite};
    border-radius: 5px;
    text-align: center;
    padding: 1rem;
    a{
        color: ${({theme}) => theme.colors.grey4};
        letter-spacing: 0.1rem;
    }

`

export const TopRightContainer = styled.div`
    display: flex;
    gap: 1rem;
    padding: 1rem;
    overflow-x: scroll;

`;

export const CourseContainer = styled.div`
    padding: 1rem;
    overflow-y: scroll;
    //border: 1px solid green;
`;

