import { InputContainer } from "./FormElements.styles";

const InputNumber = (props) => {
    //title, placeholder, value, setValue
    return ( 
        <InputContainer>
            <label>{props.title} <span className="detail">{props?.detail}</span></label>
            <input min="0" type="number" value={props.value} onChange={(e) => props.setValue(e.target.value)}/>
        </InputContainer>
     );
}
 
export default InputNumber;