import { useParams } from "react-router-dom";
import Auth from '../../Helpers/auth';
import { httpBuyCourse } from "../../Hooks/course.hooks";
import { useState } from "react";
import PrimaryButton from "../../Components/Buttons/primaryButton.component";
import {useMutation} from '@tanstack/react-query';

const BuyButton = () => {
    const {id} = useParams();
    const {data} = Auth.getProfile();

    const { mutate, isPending, isError, isSuccess } = useMutation({
        mutationFn: (data) => httpBuyCourse(data),
        mutationKey: ['userCouses']
      });

    return ( 
        <div style={{ display: 'flex', flexDirection: 'column'}}>  
            {isPending && <p>Purchasing Course...</p>}
            {isSuccess && <p className="success">Course has been purchase</p>}
            {isError && <p className="error">Course has not been purchased</p>}
            <PrimaryButton functionName={() => mutate({courseId: id, userId: data.id})} span="Buy Course" />
        </div>
     );
}
 
export default BuyButton;