import { Link } from "react-router-dom";
import { useState } from "react";
import AdminTabsComponent from "./adminTabs.component";
import { LeftAdmin, RightAdmin, TopLeftAdmin, BottomLeftAdmin, AdminContainer, AdminTable } from "./admin.styles";
import CoursesTab from "./coursesTab.component";
import UsersTab from "./userTab.component";
import CompaniesTab from "./companiesTab.component";

const Admin = () => {

    const [showCompanies, setShowCompanies] = useState(true);
    const [showCourses, setShowCourses] = useState(false);
    const [showUsers, setShowUsers] = useState(false);

    // useEffect(() => {
    //     const GetComapanies = async() => {
    //         let companies = await httpGetAllCompanies();
    //         if(companies){
    //             setCompanies(companies)
    //         }
    //         let users = await httpGetAllUsers();
    //         if(users){
    //             setUsers(users);
    //         }
    //     }
    //     GetComapanies();
    // }, [])

    return ( 
        <AdminContainer>
        <LeftAdmin>
            <TopLeftAdmin>
            <h2>Admin Dashboard</h2>
            </TopLeftAdmin>
            <BottomLeftAdmin>
            <Link to='/admin/create-user'>Create User</Link>
            <Link to='/admin/create-company'>Create Company</Link>
            <Link to='/admin/create-course'>Create Course</Link>
            </BottomLeftAdmin>
        </LeftAdmin>
        <RightAdmin>
        <AdminTabsComponent setShowCompanies={setShowCompanies} showCompanies={showCompanies}
         setShowCourses={setShowCourses} showCourses={showCourses}
        setShowUsers={setShowUsers} showUsers={showUsers}
        />
            <AdminTable>
        {showCompanies && 
            <CompaniesTab />
        }
        {showCourses && 
            <CoursesTab />
        }
        {showUsers && 
            <UsersTab />
        } 
        </AdminTable>
        </RightAdmin>
        </AdminContainer>
     );
}
 
export default Admin;