import { useState } from "react";
import {useNavigate} from 'react-router-dom';
import ArrayInput from '../../FormElements/ArrayInput';
import InputDiv from "../../FormElements/InputText";
import TextareaDiv from "../../FormElements/Textarea";
import InputNumber from "../../FormElements/InputNumber";
import CreateCourseSubmit from "./course-create-submit";
import Modal from '../../../Components/Modal/modal.component';
import { FormContainer, SubmitContainer } from "../CreateForm.styles";
import PhotosCreate from "../../../Cloudinary/CreatePhotos/createPhotos.component";
import QuestionContainerComponent from "../../FormElements/QuestionElements/QuestionContainer.component";
import TagsSelectedContainer from "../../FormElements/TagElement/TagsContainer.component";
import PrimaryButton from "../../../Components/Buttons/primaryButton.component";

const CreateCourse = () => {
    const navigate = useNavigate();
    const [courseName, setCourseName] = useState('');
    const [price, setPrice] = useState(25);
    const [description, setDescription] = useState('');
    const [expiryDate, setExpiryDate] = useState(0);
    const [certificatePrefix, setCertificatePrefix] = useState('');
    const [tags, setTags] = useState([]);
    const [image, setImage] = useState('');
    const [questions, setQuestions] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [successState, setSuccessState] = useState('');
    const [pdf, setPdf] = useState([]);
    const [video, setVideo] = useState('');
    const [videoLinks, setVideoLinks] = useState([]);
    const [doc, setDoc] = useState('');
    const [documentation, setDocumentation] = useState([]);
    const [notes, setNotes] = useState('');
  
    return (  
        <FormContainer>
        <h1>Create Course</h1>
        <hr />
        {/* Course Name */}
        <InputDiv value={courseName} title="Course Name" setValue={setCourseName} />
        {/* Description */}
        <TextareaDiv value={description} title="Course Description" setValue={setDescription} />
        {/* Price */}
        <InputNumber value={price} title="Course Price" setValue={setPrice} />
        {/* Expiry Date */}
        <InputNumber value={expiryDate} title="Expiry Date" detail="* in years" setValue={setExpiryDate} />
        {/* Certificate Prefix */}
        <InputDiv value={certificatePrefix} title="Certificate Prefix" detail="* format: FP-" setValue={setCertificatePrefix} placeholder="FP-"/>
        {/* Notes about the course */}
         <TextareaDiv value={notes} title="Notes" setValue={setNotes} />
        {/* Video Links */}
        <ArrayInput label="Video Links" single={video} arrayItems={videoLinks} setSingle={setVideo} setArray={setVideoLinks}/>
         {/* Documentation */}
         <ArrayInput label="Documentation" single={doc} arrayItems={documentation} setSingle={setDoc} setArray={setDocumentation}/>
        {/* PDF Upload */}
        <div style={{display: 'flex', justifyContent: 'space-between', marginBlock: '1rem', paddingBottom: '1rem', borderBottom: '1px solid #e6e6e6'}}>
        <label>Presentation Slideshow <span className="detail">* pdf's only</span></label>
        <PhotosCreate images={pdf} setImage={setPdf} buttonLabel="Upload PDF"/>
        </div>
        {/* Thumbnail Upload */}
        <div style={{display: 'flex', justifyContent: 'space-between', marginBlock: '1rem', paddingBottom: '1rem', borderBottom: '1px solid #e6e6e6'}}>
        <label>Thumbnail Picture</label>
        <PhotosCreate images={image} setImage={setImage} buttonLabel="Upload Image" displayPhoto={true}/>
        </div>
        {/* Tags */}
        <h4>Tags</h4>
        <hr />
        <TagsSelectedContainer tags={tags} setTags={setTags} />
        {/* Quiz */}
        <h4>Quiz</h4>
        <hr />
        <QuestionContainerComponent questions={questions} setQuestions={setQuestions}/>
        {isOpen && (
        <Modal onClose={() => setIsOpen(false)}>
        <p className="success">{successState}</p>
        <PrimaryButton functionName={() => navigate('/admin')} span="Go Home" />
         </Modal>
        )}
        <SubmitContainer>
        <CreateCourseSubmit 
        pdf={pdf}
        videoLinks={videoLinks}
        documentation={documentation}
        notes={notes}
        questions={questions} 
        courseName={courseName}
        tags={tags}
        image={image}
        description={description}
        price={price}
        expiryDate={expiryDate}
        certificatePrefix={certificatePrefix}
        setIsOpen={setIsOpen}
        setSuccessState={setSuccessState}
        />
        </SubmitContainer>
        </FormContainer>
  
    );
}
 
export default CreateCourse;