import { ModalContainer } from "./modal.styles";
import SecondaryButton from "../Buttons/secondaryButton.component";

const Modal = ({children, onClose}) => {

    return (  
        <ModalContainer className="boxShadow">
           <div className="modal swing-in-top-fwd">
            <div className="topClose"><h3 onClick={onClose}>X</h3></div>
            {children}
            <SecondaryButton functionName={onClose} span="Close" />
           </div>
        </ModalContainer>
    );
}
 
export default Modal;