import PrimaryButton from "../../../Components/Buttons/primaryButton.component";
import { httpCreateCourse } from "../../../Hooks/admin.hooks";

const CreateCourseSubmit = ({questions, pdf, notes, videoLinks, documentation, 
    image, expiryDate, certificatePrefix, courseName, tags, description, price, setSuccessState, setIsOpen}) => {
   
    const handleSubmit = async(e) => {
        e.preventDefault();
        const fullCourse = Object.assign({}, {
            courseName: courseName,
            price: price,
            description: description,
            tags: tags,
            pdf: pdf,
            notes: notes,
            videoLinks: videoLinks,
            documentation: documentation,
            image: image,
            quiz: questions,
            expiryDate: expiryDate,
            certificatePrefix: certificatePrefix
        })
        let res = await httpCreateCourse(fullCourse);
        console.log(res);
        if (res.status === 201) {       
            setSuccessState('Course has been created');
            setIsOpen(true)
           } else {
            setSuccessState('An error has occured, the course has not been created');
            setIsOpen(true)
        }
    }
    return (
     <>
     <PrimaryButton functionName={(e) => handleSubmit(e)} span="Create Course" />
    </> 
    );
}
 
export default CreateCourseSubmit;