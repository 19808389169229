import styled from 'styled-components';

export const AdminContainer = styled.main`
    width: 100%;
    display: flex;
`;

export const LeftAdmin = styled.aside`
    width: 30%;
    padding-top: 3rem;
    padding-inline: 2rem;
    /* border: 1px solid orange; */
    background-color: ${({theme}) => theme.colors.grey5};
    a{
        color: ${({theme}) => theme.colors.grey3};
    }
`;
export const TopLeftAdmin = styled.div`
h2{
    margin-bottom: 1rem;
    color: ${({theme}) => theme.colors.grey};
}
    margin-bottom: 2rem;
    border-bottom: 1px solid ${({theme}) => theme.colors.yellow3};
`;

export const BottomLeftAdmin = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    a{
        color: ${({theme}) => theme.colors.grey1};

    }
`;

export const RightAdmin = styled.section`
    width: 70%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    /* border: 1px solid green;  */
`;

export const AdminTabs = styled.div`
    display: flex;
    gap: 1rem;
    padding-block: 1rem;
    /* border: 1px solid pink; */
`;

export const AdminTable = styled.div`
    /* border: 1px solid blue; */
`;  
