import { httpCreateCompany } from "../../../Hooks/admin.hooks";
import { formatNum } from "../../../Helpers/formatPhoneNumber";
import PrimaryButton from "../../../Components/Buttons/primaryButton.component";
const CreateCompanySubmit = ({company, image, setSuccessState, notes, setIsOpen}) => {

    const CreateCompany = async(e) => {
        e.preventDefault();
        const {phoneMobile, phoneOffice} = company;
        const companyPhone = await formatNum(phoneOffice);
        const mobilephone = await formatNum(phoneMobile);
        const newCompany = Object.assign(company, {
            employees: [],
            active: true,
            notes: notes,
            logo: image,
            phoneMobile: mobilephone,
            phoneOffice: companyPhone
        })
        console.log('Total company', newCompany);
        const res = await httpCreateCompany(newCompany);
        if(res.status === 201){
            setSuccessState('Company has been created');
            setIsOpen(true)
        } else {
            setSuccessState('An error has occured, the company has not been created');
            setIsOpen(true)
        }
    }

    return (  
        <>
        <PrimaryButton functionName={(e) => CreateCompany(e)} span="Create Company" />
        </>
    );
}
 
export default CreateCompanySubmit;