import SecondaryButton from "../../../Components/Buttons/secondaryButton.component";

const EditButton = ({quest, setEditQuestion, setCreateOrEdit}) => {
    const handleEdit = (e) => {
        e.preventDefault();
        setCreateOrEdit(false);
        setEditQuestion(quest);
    };

    return ( 
        <SecondaryButton functionName={handleEdit} span="Edit Question"/>
     );
}
 
export default EditButton;