import styled from "styled-components";

export const InputContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${({theme}) => theme.colors.grey};
    padding-bottom: 1rem;
    margin-top: 1rem;
    input{
        width: 40%;
    }

`;

export const TagContainer = styled.div`
    display: flex;
    gap: 0.5rem;
    width: 100%;
    .seperate{
        width: 50%;
    }
`;
export const EachTag = styled.div`
    width: 100%;
    display: flex; 
    gap: 1rem;
    padding: 0.2rem;
`;

export const ArrayContainer = styled.div`
margin-block: 1rem;
border-bottom: 1px solid ${({theme}) => theme.colors.grey};
.mappedElements{
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}
.inputDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 1rem;
}  
input{
    width: 50%;
}

`;