import { useParams } from "react-router-dom";
import { QuizCompleteContainer, ResultsContainer, ReviewQuizContainer } from "../Completed/quiz-completed.styles";
import { httpGetSingleCourse } from "../../Hooks/course.hooks";
import { useQuery } from "@tanstack/react-query";
import UserIncompleteComponent from "../DisplayUserInfo/displayUserIncomplete.component";
import DisplayAnswersComponent from "../DisplayAnswers/displayAns.component";

const IncompleteCourse = () => {
    const {id} = useParams();

    const { data, error, isLoading } = useQuery({
        queryKey: [id],
        queryFn: () => httpGetSingleCourse(id),
        enabled: !!id,
        retry: 5,
      });

    if(error){
          return <h3>An error has occured</h3>
    }
     if(isLoading){
         return <h3>Loading...</h3>
    }

    return (  
        <QuizCompleteContainer>
            <ResultsContainer>
            <UserIncompleteComponent id={id} />
            </ResultsContainer>
            <ReviewQuizContainer>
            <h2>Review Quiz</h2>
            <hr />
            {data?.quiz?.map((question, idx) => {
                return (
                 <DisplayAnswersComponent question={question} idx={idx} />

                )
            })} 
            </ReviewQuizContainer>
        </QuizCompleteContainer>
    );
}
 
export default IncompleteCourse;