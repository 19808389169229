import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { httpGetSingleCourse } from "../../../Hooks/course.hooks";
import ArrayInput from "../../FormElements/ArrayInput";
import InputDiv from "../../FormElements/InputText";
import TextareaDiv from "../../FormElements/Textarea";
import InputNumber from "../../FormElements/InputNumber";
import EditCourseSubmit from "./cours-edit-submit.component";
import Modal from "../../../Components/Modal/modal.component";
import { FormContainer, SubmitContainer} from "../../CreateRoutes/CreateForm.styles";
import PhotosCreate from "../../../Cloudinary/CreatePhotos/createPhotos.component";
import DeleteCourseButton from "./course-delete.component";
import QuestionContainerComponent from "../../FormElements/QuestionElements/QuestionContainer.component";
import TagsSelectedContainer from "../../FormElements/TagElement/TagsContainer.component";
import PrimaryButton from "../../../Components/Buttons/primaryButton.component";

const EditCourse = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [course, setCourse] = useState([]);
    const [courseName, setCourseName] = useState(course?.courseName);
    const [price, setPrice] = useState(course?.price);
    const [description, setDescription] = useState(course?.description);
    const [expiryDate, setExpiryDate] = useState(course?.expiryDate);
    const [certificatePrefix, setCertificatePrefix] = useState(course?.certificatePrefix);
    const [tags, setTags] = useState(course?.tags);
    const [image, setImage] = useState('');
    const [questions, setQuestions] = useState(course?.quiz);
    const [isOpen, setIsOpen] = useState(false);
    const [successState, setSuccessState] = useState('');
    const [pdf, setPdf] = useState([]);
    const [video, setVideo] = useState('');
    const [videoLinks, setVideoLinks] = useState(course?.videoLinks);
    const [doc, setDoc] = useState('');
    const [documentation, setDocumentation] = useState(course?.documentation);
    const [notes, setNotes] = useState(course?.notes);

    useEffect(() => {
        const GetUser = async() => {
            let c = await httpGetSingleCourse(id);
            if(c){
                setCourse(c);
                setCourseName(c.courseName);
                setPrice(c.price);
                setDescription(c.description);
                setExpiryDate(c.expiryDate);
                //setDocumentation(c.documentation);
                setNotes(c.notes);
                //setVideoLinks(c.videoLinks);
                setCertificatePrefix(c.certificatePrefix);
                setTags(c.tags);
                setQuestions(c.quiz)
            }
        }
        GetUser();
    }, []);

    return (  
        <FormContainer>
        <h1>Edit Course</h1>
        <hr />
        {/* Course Name */}
        <InputDiv value={courseName} title="Course Name" setValue={setCourseName} />
        {/* Description */}
        <TextareaDiv value={description} title="Course Description" setValue={setDescription} />
        {/* Price */}
        <InputNumber value={price} title="Course Price" setValue={setPrice} />
        {/* Expiry Date */}
        <InputNumber value={expiryDate} title="Expiry Date" detail="* in years" setValue={setExpiryDate} />
        {/* Certificate Prefix */}
        <InputDiv value={certificatePrefix} title="Certificate Prefix" detail="* format: FP-" setValue={setCertificatePrefix} placeholder="FP-"/>
        {/* Notes about the course */}
         <TextareaDiv value={notes} title="Notes" setValue={setNotes} />
        {/* Video Links */}
        <ArrayInput label="Video Links" single={video} arrayItems={videoLinks} setSingle={setVideo} setArray={setVideoLinks}/>
         {/* Documentation */}
         <ArrayInput label="Documentation" single={doc} arrayItems={documentation} setSingle={setDoc} setArray={setDocumentation}/>
        {/* PDF Upload */}
        <div style={{display: 'flex', justifyContent: 'space-between', marginBlock: '1rem', paddingBottom: '1rem', borderBottom: '1px solid #e6e6e6'}}>
        <label>Presentation Slideshow <span className="detail">* pdf's only</span></label>
        <PhotosCreate images={pdf} setImage={setPdf} buttonLabel="Upload PDF"/>
        </div>
        {/* Thumbnail Upload */}
        <div style={{display: 'flex', justifyContent: 'space-between', marginBlock: '1rem', paddingBottom: '1rem', borderBottom: '1px solid #e6e6e6'}}>
        <label>Thumbnail Picture</label>
        <PhotosCreate images={image} setImage={setImage} buttonLabel="Upload Image" displayPhoto={true}/>
        </div>
        {/* Tags */}
        <h4>Tags</h4>
        <hr />
        <TagsSelectedContainer tags={tags} setTags={setTags} />
        {/* Quiz */}
        <h4>Quiz</h4>
        <hr />
        <QuestionContainerComponent questions={questions} setQuestions={setQuestions}/>
        {isOpen && (
        <Modal onClose={() => setIsOpen(false)}>
        <p className="success">{successState}</p>
        <PrimaryButton functionName={() => navigate('/admin')} span="Go Home" />
         </Modal>
        )}
        <SubmitContainer>
            <DeleteCourseButton setSuccessState={setSuccessState} setIsOpen={setIsOpen} id={id} />
        <EditCourseSubmit
        pdf={pdf}
        videoLinks={videoLinks}
        documentation={documentation}
        notes={notes}
        questions={questions} 
        courseName={courseName}
        tags={tags}
        image={image}
        description={description}
        price={price}
        expiryDate={expiryDate}
        certificatePrefix={certificatePrefix}
        setIsOpen={setIsOpen}
        setSuccessState={setSuccessState}
        />
        </SubmitContainer>
        </FormContainer>
    );
}
 
export default EditCourse;