import { EachAnswer, EachQuestionContainer } from "../quizStyles.styles";
import { useState } from "react";


const EachQuestion = ({ q, i, setCorrect, correct }) => {
  const { id, question, correctAnswer, answer1, answer2, answer3, answer4 } = q;
  const [selected, setSelected] = useState();
  const checkAnswer = (e) => {
    if (e.target.value === correctAnswer) {
      setCorrect(correct + 1);
    }
    setSelected(e.target.value);
  };

  return (
    <EachQuestionContainer >
      <div key={id}>
      <h3>
        {i + 1}. {question}
      </h3>
      {answer1 && (
        <EachAnswer className={selected === answer1 ? "selectedAnswer" : ""}>
          <input
            type="radio"
            id={question}
            name="answer"
            value={answer1}
            onClick={checkAnswer}
          />
          <label htmlFor={question}>{answer1}</label>
        </EachAnswer>
      )}
      {answer2 && (
        <EachAnswer className={selected === answer2 ? "selectedAnswer" : ""}>
          <input
            type="radio"
            id={id}
            name="answer"
            value={answer2}
            onClick={checkAnswer}
          />
          <label htmlFor={id}>{answer2}</label>
        </EachAnswer>
      )}
      {answer3 && (
        <EachAnswer className={selected === answer3 ? "selectedAnswer" : ""}>
          <input
            type="radio"
            id={id}
            name="answer"
            value={answer3}
            onClick={checkAnswer}
          />
          <label htmlFor={id}>{answer3}</label>
        </EachAnswer>
      )}
      {answer4 && (
        <EachAnswer className={selected === answer4 ? "selectedAnswer" : ""}>
          <input
            type="radio"
            id={id}
            name="answer"
            value={answer4}
            onClick={checkAnswer}
          />
          <label htmlFor={question}>{answer4}</label>
        </EachAnswer>
      )}
      </div>
    </EachQuestionContainer>
  );
};

export default EachQuestion;
