import SecondaryButton from "../../../Components/Buttons/secondaryButton.component";

const DeleteQuestion = ({questions, setQuestions, quest}) => {
    return (  
        <SecondaryButton functionName={(e) => {
            e.preventDefault();
            let filtered = questions.filter(question => question.id !== quest.id);
            setQuestions(filtered);
        }} span="Delete Question" />
    );
}
 
export default DeleteQuestion;