import { httpGetUser } from "../../../Hooks/user.hooks";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FormContainer, SubmitContainer } from "../../CreateRoutes/CreateForm.styles";
import Modal from "../../../Components/Modal/modal.component";
import EditUserSubmit from "./user-edit-submit.component";
import InputDiv from "../../FormElements/InputText";
import PrimaryButton from "../../../Components/Buttons/primaryButton.component";

const EditUser = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [user, setUser] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [successState, setSuccessState] = useState('');
    const[password, setPassword] = useState('');
    useEffect(() => {
        const GetUser = async() => {
            let foundUser = await httpGetUser(id);
            if(foundUser){
                setUser(foundUser);
            }
        }
        GetUser();
    }, [id]);
    // firstName, lastName, type, email, active, createdAt, password
    return (
        <FormContainer>
        <h1>Edit User</h1>
        <hr />
        <InputDiv title="First Name" value={user.firstName} name="firstName" nestedValue={setUser} upperValue={user} />
        <InputDiv title="Last Name" value={user.lastName} name="lastName" nestedValue={setUser} upperValue={user} />
        <InputDiv title="Email" value={user.email} name="email" nestedValue={setUser} upperValue={user} />
        <InputDiv title="Password" value={password} name="password" setValue={setPassword} />
        {/* Map through the companies and choose one */}
        {/* For now it's just going to be a type in */}
        {/* <InputDiv title="Company" value={user.company} name="company" nestedValue={setUser} upperValue={user} /> */}
        {isOpen && (
        <Modal onClose={() => setIsOpen(false)}>
        <p className="success">{successState}</p>
        <PrimaryButton functionName={() => navigate('/admin')} span="Go Home" />
         </Modal>
        )}
        <SubmitContainer>
        <EditUserSubmit user={user} password={password} setIsOpen={setIsOpen} setSuccessState={setSuccessState} />
        </SubmitContainer>
        </FormContainer>
      );
}
 
export default EditUser;