import { Cloudinary } from '@cloudinary/url-gen';
//import { thumbnail } from '@cloudinary/url-gen/actions/resize';
//import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';
//import { format, quality } from '@cloudinary/url-gen/actions/delivery';
//import { AdvancedImage, placeholder, lazyload } from '@cloudinary/react';
//import { byRadius } from '@cloudinary/url-gen/actions/roundCorners';

const cloud = new Cloudinary({cloud: {cloudName: 'dteb3vc5h'}});

const CloudImage = ({ publicId }) => {
  //console.log('cimage', publicId)
  const myImage = cloud
    .image(publicId).toURL()
    // .toURL();
    // .resize(thumbnail().width(150).height(100))
    // .delivery(format('auto'))
    // .delivery(quality('auto'));
//maxHeight="100px" max-width="150px"
  return (
     <img className="thumbnail" src={myImage} alt="course" />
    // <AdvancedImage
    //   cldImg={myImage}
      
    //  // plugins={[placeholder({mode: 'blur'}), lazyload({rootMargin: '0px 20px 10px 30px', threshold: 0.25})]}
    //   //className="rounded-lg shadow-lg"
    // />
  );
};
export default CloudImage;
