import styled from 'styled-components';

export const AuthContainer = styled.main`
    display: flex;
    justify-content: center;
`;

export const AuthContainer2 = styled.section`
    height: 80%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
   
`;
export const PictureSide = styled.section`
    width: 40%;
    height: 80%;
    display: flex;
    /* border: 2px solid red; */
    img {
        width: 100%;
        height: auto;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
    }
    @media screen and (width <=1150px){
       display: none;
    }
`;

export const AuthenticationSide = styled.section`
    width: 40%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    /* border: 2px solid blue; */
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: ${({theme})=> theme.colors.offWhite};
    h2{
        margin-bottom: 1rem;
        color: ${({theme})=> theme.colors.grey5};
    }
    form{
        padding: 1rem;
        /* border: 1px solid green; */
        width: 90%;
        display: flex;
        flex-direction: column;
    }
    .nameDiv{
        display: flex;
        gap: 1rem;
        /* border: 1px solid orange; */
    }
    .nameDiv input{
        width: auto;
        max-width: 250px;
        /* max-width: 250px; */
    }
    .outerSwitch{
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: start;
    }
    .switchPage{
        display: flex;
        align-items: center;
        justify-content: start;
        width: 100%;
        padding-top: 0.5rem;
        flex-wrap: wrap;
    }
    button{
        width: 150px;
    }
    @media screen and (width <=1150px){
        width: 100%;
        height: 80%;
        //border: 1px solid pink;
    }
    @media screen and (width <= 900px){
       // border: 1px solid orange;
        width: 100%;
        .nameDiv{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        
    }
    .nameDiv input{
        width: 350px;
    }
    }
`;

export const EachInput = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 0.6rem;
    margin-bottom: 1.5rem;
    label{
        font-weight: bold;
    }
    input{
        width: 350px;
    }
    span{
        font-size: 0.8rem;
        color: ${({theme}) => theme.colors.grey3};   
    }
    .passwordSwitch:hover{
        color: ${({theme}) => theme.colors.yellow4}; 
    }
    @media screen and (width <= 600px){
        input {
            width: 250px;
        }
    }

`;
