// import Loader from '../Components/Loader/loader.component';
// import Default from '../Visuals/Images/Default.jpg';
// import { useSelector } from 'react-redux';
// import BreadCrumbs from '../Components/Breadcrumbs/breadcrumbs.component';
// import { CourseRightContainer } from './courseList.styles';
//import { CourseCardComponent, CourseInfo, CourseImage } from '../Routes/OnlineTraining/training.styles';

const AllCourses = () => {
    //const courses = useSelector(state => state.courses.allCourses);
  
    return ( 
        <main>
            <h1>This is commented out All Courses</h1>
            {/* <h1>Courses/CourseList</h1>
        <BreadCrumbs one='admin' oneLink='/admin' two='course' />
        {courses ? 
        <CourseRightContainer>
          {courses.map((c) => {
                return <CourseCardComponent className='boxShadow' key={c._id}>
                    <CourseImage>
                        <img src={Default} alt="course" />
                    </CourseImage>
                    <CourseInfo>
                        <h4>{c.courseName}</h4>
                        <p>{c.description.length <= 100 ? c.description: c.description.slice(0, 100) + ' ...'}</p>
                        <div className='buttonContainer'>
                        </div>
                    </CourseInfo>
                </CourseCardComponent>
            })}
        </CourseRightContainer>    
        : 
        <Loader />
        } */}
        </main>
     );
}
 
export default AllCourses;