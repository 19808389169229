import { httpDeleteCourse } from "../../../Hooks/admin.hooks";
import SecondaryButton from "../../../Components/Buttons/secondaryButton.component";

const DeleteCourseButton = ({setSuccessState, setIsOpen, id}) => {

    const handleDelete = async(e) => {
        e.preventDefault();
        let res = await httpDeleteCourse(id);
        if (res.status === 201) {       
            setSuccessState('Course has been deleted');
            setIsOpen(true)
           } else {
            setSuccessState('An error has occured, the course has not been deleted');
            setIsOpen(true)
        }
    }

    return ( 
        <>
        <SecondaryButton function={handleDelete} span="Delete Course" />
        </>
     );
}
 
export default DeleteCourseButton;