import { useEffect, useRef, useState } from "react";
//import { useNavigate } from "react-router-dom";
import DisplayPhoto from "./displayPhotos.component";
import SecondaryButton from "../../Components/Buttons/secondaryButton.component";

const PhotosCreate = ({images, setImage, buttonLabel, displayPhoto}) => {
//addNewImage, addNewPhotos
    //const[isOpen, setIsOpen] = useState(false);
    const [display, setDisplay] = useState('');
    const [error, setError] = useState('');
    //const navigate = useNavigate();
    const cloudinaryRef = useRef();
    const widgetRef = useRef();

    useEffect(() => {
      cloudinaryRef.current = window.cloudinary;
      widgetRef.current = cloudinaryRef.current.createUploadWidget({
        cloudName: 'dteb3vc5h',
        uploadPreset: 'yoda_preset'
      }, function(error, result){
        if(result.event === 'success'){
          let newImage = {publicId: result.info.public_id};
          let newImageURL = result.info.url;
        //   addNewImage(newImage);
        //   addNewPhotos(newImageURL)
        setImage(newImage);
        setDisplay(newImageURL);
        } else if(error){
          setError('Could not upload image')
        } 
      });
    }, [setImage]);

    return ( 
        <>
        {displayPhoto ?
         <>
      <div style={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
        <SecondaryButton functionName={(e) => {
            e.preventDefault();
            widgetRef.current.open();
          }} span={buttonLabel} />
          {error && <p className="error">{error}</p>}
      </div>
         <DisplayPhoto photos={display}/>
         </>
      :
      <div style={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
        <SecondaryButton functionName={(e) => {
            e.preventDefault();
            widgetRef.current.open();
          }} span={buttonLabel} />
          {error && <p className="error">{error}</p>}
      </div>
      }
        </>
     );
}
 
export default PhotosCreate;