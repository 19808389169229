import CreateQuestion from "./CreateQuestion.component";
import DisplayQuestion from "./DisplayQuestion.component";
import EditQuestion from "./EditQuestion.component";
import { useState } from "react";

const QuestionContainerComponent = ({questions, setQuestions}) => {
    //Sets the form for either creating or editing the quiz question
    const [createOrEdit, setCreateOrEdit] = useState(true);
    const [editQuestion, setEditQuestion] = useState([]);
    console.log('CREATE OR EDIT', createOrEdit);
    return ( 
        <>
        <div style={{display: 'flex', justifyContent: 'center'}}>
        {createOrEdit ? 
        <CreateQuestion questions={questions} setQuestions={setQuestions}/>
        : 
        <EditQuestion editQuestion={editQuestion} setQuestions={setQuestions} questions={questions} setEditQuestion={setEditQuestion} setCreateOrEdit={setCreateOrEdit}/>
        }
        </div>
        <DisplayQuestion questions={questions} setQuestions={setQuestions} 
        setCreateOrEdit={setCreateOrEdit} setEditQuestion={setEditQuestion}/>
        </>
     );
}
 
export default QuestionContainerComponent;