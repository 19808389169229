import { useQuery } from "@tanstack/react-query";
import { httpGetUser } from "../../Hooks/user.hooks";
import UserRouterComponent from "./userRouter.component";

const UserCourses = ({user, chosen}) => {
    const {data, error, isLoading} = useQuery({
        queryKey: ['userCourses'],
        queryFn: () =>  httpGetUser(user?.id),
        retry: 5
      });
     // console.log('USER DATA COURSES', data, 'USER', user, 'CHOSEN', chosen)

      if(error){
        return <h3>An error occured getting the courses</h3>
      }

      if(isLoading){
        return <h3>Loading...</h3>
      }
    return ( 
        <>
        <UserRouterComponent courses={data} user={user} chosen={chosen}/>
        </>
     );
}
 
export default UserCourses;