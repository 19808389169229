import { useState } from "react";
import InputDiv from "../../FormElements/InputText";
import Modal from "../../../Components/Modal/modal.component";
import CreateCompanySubmit from "./company-create-submit";
import { useNavigate } from "react-router-dom";
import TextareaDiv from "../../FormElements/Textarea";
import PhotosCreate from "../../../Cloudinary/CreatePhotos/createPhotos.component";
import { FormContainer, SubmitContainer } from "../CreateForm.styles";
import PrimaryButton from "../../../Components/Buttons/primaryButton.component";

const CreateCompany = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [successState, setSuccessState] = useState('');
    const [image, setImage] = useState('');
    // Logo needs to be set up with cloudinary
    const [company, setCompany]  = useState({
        companyName: '',
        mainContact: '',
        secondaryContact: '',
        phoneOffice: '',
        phoneMobile: '',
        address: '',
        website: '',
    });
    const[notes, setNotes] = useState('');
   
    return ( 
        <FormContainer>
        <h1>Create a company</h1>
        <hr />
        <InputDiv title="Company Name" value={company.companyName} name="companyName" nestedValue={setCompany} upperValue={company} />
        <InputDiv title="Main Contact" value={company.mainContact} name="mainContact" nestedValue={setCompany} upperValue={company} />
        <InputDiv title="Secondary Contact" value={company.secondaryContact} name="secondaryContact" nestedValue={setCompany} upperValue={company} />
        <InputDiv title="Office Phone Number" value={company.phoneOffice} name="phoneOffice" nestedValue={setCompany} upperValue={company} />
        <InputDiv title="Mobile Phone Number" value={company.phoneMobile} name="phoneMobile" nestedValue={setCompany} upperValue={company} />
        <InputDiv title="Website" value={company.website} name="website" nestedValue={setCompany} upperValue={company} />
        <InputDiv title="Address" value={company.address} name="address" nestedValue={setCompany} upperValue={company} />
        <TextareaDiv title="Notes" value={notes} setValue={setNotes} />
        <div style={{display: 'flex', gap: '1.5rem', alignItems: 'center', marginBlock: '1rem'}}>
        <label>Company Logo</label>
        <PhotosCreate images={image} setImage={setImage} buttonLabel="Company Logo"/>
        </div>
        {isOpen && (
        <Modal onClose={() => setIsOpen(false)}>
        <p className="success">{successState}</p>
        <PrimaryButton functionName={() => navigate('/admin')} span="Go Home" />
         </Modal>
        )}
        <SubmitContainer>
        <CreateCompanySubmit image={image} company={company} notes={notes} setIsOpen={setIsOpen} setSuccessState={setSuccessState} />
        </SubmitContainer>
        </FormContainer>
     );
}
 
export default CreateCompany;