import {  CourseContainer, TagContainer,  OnlineCoursesContainer,
    SearchContainer, SearchBarContainer} from './training.styles';
import RadioTag from './RadioTag';
import Modal from '../../Components/Modal/modal.component'
import CourseCardSingle from './courseCard.component';
import { httpGetAllCourses } from '../../Hooks/course.hooks';
import { useState, useEffect } from 'react';
import { httpFilterBySearch, httpFilterByTag } from '../../Hooks/course.hooks';
import PrimaryButton from '../../Components/Buttons/primaryButton.component';

const OnlineTraining = () => {
    const [allCourses, setAllCourses] = useState();
    const [displayCourses, setDisplayCourses] = useState();
    const [selectedTag, setSelectedTag] = useState('all');
    const [isOpen, setIsOpen] = useState(false);
    const [searching, setSearching] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        const GetCourses = async() => {
            let res = await httpGetAllCourses();
            if(res){
                 setDisplayCourses(res)
                 setAllCourses(res)
            } else {
                setError('Could not get the courses')
            }
        }
        GetCourses();
    }, [])
   
    const searchQuery = async(e) => {
        e.preventDefault();
        let searchedForResults = await httpFilterBySearch(searching);
        if(searchedForResults){
            setDisplayCourses(searchedForResults)
        }
    }

    const tagChange = async(selectedTag) => {
            if(selectedTag === 'all'){
                setDisplayCourses(allCourses);
            } else {
            let searchedForResults = await httpFilterByTag(selectedTag);
            if(searchedForResults){
                 setDisplayCourses(searchedForResults)
            }
    }
}

    return ( 
        <OnlineCoursesContainer>
        <SearchContainer>
        <h1>Online courses</h1>
        <SearchBarContainer>
            <input type='text' onChange={(e) => setSearching(e.target.value)} value={searching} name="searching"/>
            <PrimaryButton functionName={searchQuery} span="search" />
        </SearchBarContainer>
        </SearchContainer>
        <section style={{display: 'flex'}}> 
        <TagContainer>
        <RadioTag tagChange={tagChange} selectedTag={selectedTag} setSelectedTag={setSelectedTag} />
        </TagContainer>
            <CourseContainer>
            {error && <h3>There was an error getting the courses</h3>}
            {displayCourses?.map((c) => {
                return <CourseCardSingle c={c}/>
            })}
            </CourseContainer>
            {isOpen && (
        <Modal onClose={() => setIsOpen(false)}>
        <RadioTag />
         </Modal>
        )}
        </section>
        </OnlineCoursesContainer>
     );
}
 
export default OnlineTraining;