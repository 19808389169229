const API_URL = process.env.REACT_APP_BACKEND_URL;

const token = localStorage.getItem('yoda_token');

async function httpCompleteCourse(course, id){
    try{
        return await fetch(`${API_URL}/user/complete`, {
            method: 'put',
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({course, id})
          })
    } catch(err){
        return err;
    }
}

async function httpGetAllUsers(){
    try{
        let user = await fetch(`${API_URL}/user/allUsers`);
        return user.json();
     } catch(err){
         return err;
     }
}

async function httpLoginUser(userInfo){
    return await fetch(`${API_URL}/user/login`, {
        method: 'post',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userInfo)
      })
}

async function httpSignUpUser(userInfo){
    return await fetch(`${API_URL}/user/signup`, {
        method: 'post',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userInfo)
      })
}

async function httpGetUser(userId){
    try{
        let user = await fetch(`${API_URL}/user/${userId}`, {
            headers: {
                authorization: token ? `Bearer ${token}`: ''
            }
        });
        return user.json();
     } catch(err){
         return err;
     }
}



export {
    httpCompleteCourse,
    httpGetAllUsers,
    httpLoginUser,
    httpSignUpUser,
    httpGetUser
}