const API_URL = process.env.REACT_APP_BACKEND_URL;

async function httpCreateCourse(course){
    try{
        return await fetch(`${API_URL}/admin/createCourse`, {
            method: 'post',
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(course)
          });
     } catch(err){
         return err;
     }
}

async function httpCreateCompany(company){
    try{
        return await fetch(`${API_URL}/admin/createCompany`, {
            method: 'post',
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(company)
          })
     } catch(err){
         return err;
     }   
}

async function httpCreateUser(user){
    try{
        return await fetch(`${API_URL}/admin/createUser`, {
            method: 'post',
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(user)
          })
     } catch(err){
         return err;
     }
}

async function httpEditCourse(courseId, course){
    try{
        return await fetch(`${API_URL}/admin/editCourse/${courseId}`, {
            method: 'put',
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(course)
          })
     } catch(err){
         return err;
     }
}

async function httpEditCompany(companyId, company){
    try{
        return await fetch(`${API_URL}/admin/editCompany/${companyId}`, {
            method: 'put',
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(company)
          })
     } catch(err){
         return err;
     }
}

async function httpEditUser(userId, user){
    try{
        return await fetch(`${API_URL}/admin/editUser/${userId}`, {
            method: 'put',
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(user)
          })
     } catch(err){
         return err;
     }
}


async function httpGetAllCompanies(){
  try{
      let companies =  await fetch(`${API_URL}/admin/companies`);
       return companies.json();
   } catch(err){
       return err;
   }
}

async function httpGetAllUsers(){
  try{
      let users =  await fetch(`${API_URL}/admin/users`);
       return users.json();
   } catch(err){
       return err;
   }
}

async function httpDeleteCourse(id){
    try{
        return await fetch(`${API_URL}/admin/deleteCourse/${id}`, {
            method: 'delete',
            headers: {
              "Content-Type": "application/json",
            }
          });

     } catch(err){
         return err;
     }
}

export {
    httpCreateCompany,
    httpCreateCourse,
    httpCreateUser,
    httpEditCompany,
    httpEditCourse,
    httpEditUser,
    httpGetAllCompanies,
    httpGetAllUsers,
    httpDeleteCourse
}