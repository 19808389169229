import PrimaryButton from "../../../Components/Buttons/primaryButton.component";
import { httpEditUser } from "../../../Hooks/admin.hooks";

const EditUserSubmit = ({user, password,setIsOpen, setSuccessState}) => {

    const handleSubmit = async(e) => {
        e.preventDefault();
        const editedUser = Object.assign(user,{
            password: password
        });
        console.log('THE SENDING USER', editedUser);
        let res = await httpEditUser(editedUser._id, editedUser);
        if(res.status === 201){
            setSuccessState('User has been edited');
            setIsOpen(true);
        } else {
            setSuccessState('User has not been edited, try again');
            setIsOpen(true);
        }
    }

    return (  
        <>
        <PrimaryButton functionName={(e) => handleSubmit(e)} span="Edit User" />
        </>
    );
}
 
export default EditUserSubmit;