import styled from "styled-components";

export const QuizContainer = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    /* border: 1px solid orange; */
    padding: 0.5rem;
    .item{
        font-size: 1.6rem;
    }
    .list{
        font-size: 1.3rem;
    }
`;
export const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin: 1.5rem;
`;

export const QuestionContainer = styled.div`
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    padding: 1em;
    button{
        width: 150px;
    }
    p{
        font-size: 1.3rem;
        border-bottom: 1px solid ${({theme}) => theme.colors.grey};
    }

`;