import { InputContainer } from "./FormElements.styles";

const InputDiv = (props) => {
    const changed = (e) => {
        if(props.setValue){
            //Values Needed:  title, placeholder, value, setValue
             props?.setValue(e.target.value)
        } else if (props.nestedValue){
            //Values Needed:  title="Question" value={quest.question} name="question" nestedValue={setQuest} upperValue={quest}
            const {name, value} = e.target;
            props?.nestedValue({...props?.upperValue, [name]: value})
        }
    }
    return ( 
        <InputContainer>
            <label className={props?.className}>{props?.title} <span className="detail">{props?.detail}</span></label>
            <input type="text" required placeholder={props?.placeholder} name={props?.name} value={props?.value} onChange={(e) => changed(e)}/>
        </InputContainer>
     );
}
 
export default InputDiv;