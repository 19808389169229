import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router-dom";
import { quizPercentage } from '../Helpers/quizPercentage';
import { addYears, format } from "date-fns";
import Auth from '../Helpers/auth';
import { useState } from 'react';
import { httpCompleteCourse } from '../Hooks/user.hooks';
import PrimaryButton from '../Components/Buttons/primaryButton.component';

const QuizSubmit = ({id, correct, length, courseName, course}) => {
    //console.log('QUIZ SUBMIT INCOMING VALUES', id, correct, length, courseName, course)
    const navigate = useNavigate();
    const[err, setErr] = useState('');
    const {data} = Auth.getProfile();

    const submitQuiz = async(e, id) => {
        e.preventDefault();
        let isCompleted = await quizPercentage(correct, length);
       
        if(isCompleted >= 60){
            let date = Date.now();
            const dateFormatted = format(new Date(), "MMMM dd, yyyy");
            let expiryDate = addYears(date, 3);
            let expired = format(expiryDate, "MMMM dd, yyyy");
            let cert = String(course.certificateCount);
            let certificate = `${course.certificatePrefix}${cert.padStart(4, '0')}`;
            let updatedCourse = {
                id: uuidv4(),
                courseId: id,
                courseName,
                certNum: certificate,
                complete: true,
                score: isCompleted,
                date: dateFormatted,
                expiryDate: expired,
            }
            let res = await httpCompleteCourse(updatedCourse, data.id);
            if(res.ok){
                navigate(`/course/quiz/complete/${id}`);
            } else {
                setErr('Not able to submit the course');
            }
        } else {
            navigate(`/course/quiz/incomplete/${id}`);
        }
    }

    return ( 
        <>
        {err && <p className='error'>{err}</p>}
        <PrimaryButton functionName={(e) => submitQuiz(e, id)} span="Submit Quiz" />
        </>
     );
}
 
export default QuizSubmit;