import EachComponent from "../../Components/Entity/each.component";
import { useQuery } from "@tanstack/react-query";
import { httpGetAllCompanies } from "../../Hooks/admin.hooks";

const CompaniesTab = () => {
    const {data, error, isLoading} = useQuery({
        queryKey: ['companies'],
        queryFn: () =>  httpGetAllCompanies(),
        retry: 5
    });
    if(error){
        return <h3>An error occured getting the courses</h3>
    }
    if(isLoading){
        return <h3>Loading...</h3>
    }
    return ( 
        <section>
                <h2>Companies:</h2>
                {data?.map((c) => {
                    return <EachComponent key={c?._id} name={c?.companyName} route={`/admin/edit-company/${c?._id}`}/>
                })} 
            </section>
    );
}
 
export default CompaniesTab;