import styled from 'styled-components';

export const MainContainer = styled.main`
    //height: 100vh;
    //overflow-y: scroll;
    overflow-x: hidden;
    

`;

export const MainSection = styled.section`
    margin-top: 1rem;
    overflow-x: hidden;
`;

export const LightSection = styled.section`
   // padding: 2rem;
`;

export const DarkSection = styled.aside`
    background-color: ${({theme}) => theme.colors.grey6};
    color:  ${({theme}) => theme.colors.white};
    padding: 2rem;
`;

export const TitleSection = styled.div`
    padding: 0;
    width: 100%;
    //background-image: url('../Visuals/Images/small.png');
    height: 50vh;
     border: 1px soid yellow;
    @keyframes slideInFromLeft {
    0% {
    transform: translateX(-100%);
    }
    100% {
    transform: translateX(0);
    }
    }
     .text{
        animation: 1s ease-out 0s 1 slideInFromLeft;
        position: absolute;
        color: var(--white);
        top: 7%;
        left: 4%;
    }
    .text h1{
        font-weight: bold;
        font-size: 3.5rem;
        color: ${({theme}) => theme.colors.grey7};
    } 
    
    .overlay {
        height: auto;
        background-color: rgba(0,0,0,1); 
    }
    img{
        height: 50vh;
        max-height: 50vh;
        width: 100vw;
        max-width: 100vw;
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden; 
        object-fit: cover;
        opacity: 0.8;  
    }

    @media screen and (width <= 700px){
        height: 30vh;
        img{
            height: 30vh;
            max-height: 30vh;
        }
        .text{
            top: 4%;
            left: 4%
        }
        .text h1{
            font-weight: bold;
            font-size: 1.5rem;
            color: ${({theme}) => theme.colors.grey7};
        }
        .text span{
            font-size: 0.7rem;
        }
       }

    @media screen and (width >= 1500px){
        height: 60vh;
        img{
            height: 60vh;
            max-height: 60vh;
        }
    }
`;

export const SpecificsSection = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    overflow-x: hidden;
    div{
        width: 30%;
        text-align: center;
    }
    p{
        text-transform: uppercase;
        font-size: 1.1rem;
        color: ${({theme}) => theme.colors.orange3};
    }
    @media screen and (width <= 800px){
        flex-direction: column;
        flex-wrap: wrap;
        overflow-x: hidden;
        gap: 0.5rem;
        div{
            width: 100%;
            max-width: 100%;
        }
        p{
            font-size: 0.8rem;
        }
    }
`;

export const AboutUsSection = styled.div`
    padding: 2rem;
    overflow-x: hidden;
`;

export const ServicesSection = styled.div`
    h2{
        color: ${({theme}) => theme.colors.yellow5};
    }
    p{
        font-size: 1.3rem;
        color: ${({theme}) => theme.colors.grey4};
    }
    
   
`;
export const ServicesContainer = styled.section`
    h2{
        margin-bottom: 1rem;
    }
    ul{
        margin-left: 1.2rem;
        list-style-type: square ;
    }
    li{
        font-size: 1.2rem;
        padding-top: 0.5rem;
    }
    li:first-child{
        color: ${({theme}) => theme.colors.yellow4};
    }
    li:nth-child(2){
        color: ${({theme}) => theme.colors.yellow3};
    }
    li:nth-child(3){
        color: ${({theme}) => theme.colors.yellow2};
    }
    li:nth-child(4){
        color: ${({theme}) => theme.colors.yellow1};
    }
    @media screen and (width <= 800px){
        li{
            font-size: 0.9rem;
        }
    }

`;

export const ContactUsSection = styled.div`
    padding: 2rem;
    overflow-x: hidden;
    p{
        color: ${({theme}) => theme.colors.grey7};
        font-weight: bold;
    }

`;
