import styled from 'styled-components';

export const QuizCompleteContainer = styled.main`
    width: 80%;
    padding-top: 3%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 3rem;
    margin: 0 auto;
    @media screen and (width <= 600px){
        width: 95%;
       // border: 1px solid green;
        
    }

`;

export const ButtonContainer = styled.div`
    display: flex;
    gap: 2rem;
    margin-block: 3rem;
    button{
        min-width: 150px;
    }
    @media screen and (width <= 600px){
         margin-block: 1rem;
        flex-direction: column-reverse;
        //border: 1px solid green;
        gap: 1rem;
        button{
            min-width: 100%;
        }
        
    }

`;

export const ResultsContainer = styled.section`
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    align-items: center;
    margin-top: 2rem;
    h2{
        color: ${({theme}) => theme.colors.grey6};
        text-align: center;
        margin-bottom: 0.5rem;
    }
    .courseName{
        color: ${({theme}) => theme.colors.grey4};
    }
    @media screen and (width <= 600px){
        margin-top: 1rem;
        gap: 0.5rem;  
    }

`

export const EachAnswer = styled.div`
    margin-left: 3rem;
    width: 40%;
    @media screen and (width <= 600px){
       margin-left: 1rem;
       width: 100%;
      //border: 1px solid green;
        
    }
`;

export const ReviewQuizContainer = styled.section`
    width: 80%;
    h2{
        color: ${({theme}) => theme.colors.grey4};
    }
    @media screen and (width <= 600px){
       width: 100%;
      // border: 1px solid green;
       
   }


`;