import { useState, useEffect } from "react";
import { httpGetSingleCourse } from "../Hooks/course.hooks";
import { useParams } from "react-router-dom";
import EachQuestion from "./EachQuestion/eachQuestion.component";
import { QuizContainer, ButtonContainer } from "./quizStyles.styles";
import QuizSubmit from "./quizSubmit.component";
import { useQuery } from "@tanstack/react-query";
import BreadCrumbs from "../Components/Breadcrumbs/breadcrumbs.component";

const QuizComponent = () => {
    const {id} = useParams();
     const [correct, setCorrect] = useState(0);

    const {data, error, isLoading} = useQuery({
        queryKey: [id],
        queryFn: () =>  httpGetSingleCourse(id),
        enabled: !!id,
        retry: 5
    });
    const quizLength = data?.quiz.length;

    if(isLoading){
        return <h3>Loading...</h3>
    }
    if(error){
        return <h3>An error has occured</h3>
    }

    return (  
        <QuizContainer>
            <BreadCrumbs one={data?.courseName} oneLink={`/course/home/${data?.courseName}`} two="Quiz" />
            <h1>{data?.courseName} Quiz</h1>
            <span className="italicSpan">You must have a score of 60% or higher to pass this course</span>
                {data.quiz?.map((q, i) => {
                   return <EachQuestion key={q.id} i={i} q={q} setCorrect={setCorrect} correct={correct}/>
                })}
                <ButtonContainer>
                    <QuizSubmit id={data?._id} correct={correct} length={quizLength} courseName={data?.courseName} course={data}/>
                </ButtonContainer>
        </QuizContainer>
    );
}
 
export default QuizComponent;