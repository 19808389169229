import { QuestionContainer } from "./Quiz.styles";
import DeleteQuestion from "./DeleteQuestion.component";
import EditButton from "./EditButtonQuestion.component";

const DisplayQuestion = ({questions, setQuestions, setEditQuestion, setCreateOrEdit}) => {
    return (
        <aside>
        {questions.map((quest, index) => {
            console.log('EACH Quest', quest)
            return <QuestionContainer key={quest.id}>
                <p style={{fontSize: '1.5rem', fontWeight: 'bold'}}>Question {index + 1}: {quest.question}</p>
                {quest?.answer1 && <p>Answer 1: {quest?.answer1} </p>}
                {quest?.answer2 && <p>Answer 2: {quest?.answer2}</p>}
                {quest?.answer3 && <p>Answer 3: {quest?.answer3}</p>}
                {quest?.answer4 && <p>Answer 4: {quest?.answer4}</p>}
                <p>Correct Answer: {quest.correctAnswer}</p>
                <div style={{display: "flex", gap: "2rem"}}>
                <EditButton quest={quest} setCreateOrEdit={setCreateOrEdit} setEditQuestion={setEditQuestion} />
                <DeleteQuestion questions={questions} setQuestions={setQuestions} quest={quest} />
                </div>
            </QuestionContainer>
            })}
        </aside>
      );
}
 
export default DisplayQuestion;