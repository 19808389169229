import CertificateBackground from "../../Visuals/Images/Certificate.png";
import jsPDF from "jspdf";

const Certificate = ({
  name,
  course,
  dateCompleted,
  expiryDate,
  certNumber,
}) => {
  const generateCert = (
    name,
    course,
    dateCompleted,
    expiryDate,
    certNumber
  ) => {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "in",
      format: [8, 11],
    });

    doc.addImage(
      CertificateBackground,
      "PNG",
      0,
      0,
      doc.internal.pageSize.getWidth(),
      doc.internal.pageSize.getHeight()
    );
    doc.setFontSize(36);
    doc.setFont("helvetica");
    doc.text(name, 5.5, 4.4, { align: "center" });
    doc.setFontSize(20);
    doc.text(course, 5.5, 5.5, { align: "center" });
    doc.setFontSize(12);
    doc.text(dateCompleted, 2.4, 6.3);
    doc.text(expiryDate, 6.7, 6.3);
    doc.text(certNumber, 6.7, 7.2);
    doc.save(`${name}-${course}.pdf`);
  };

  return (
    <>
      <button
        className="primaryButton boxShadow"
        style={{ display: "flex", alignItems: "center", gap: "1rem" }}
        onClick={() =>
          generateCert(name, course, dateCompleted, expiryDate, certNumber)
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          className="icon icon-certificate"
        >
          <path
            className="primaryIcon"
            d="M4 3h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2zm16 12V7a2 2 0 0 1-2-2H6a2 2 0 0 1-2 2v8a2 2 0 0 1 2 2h12c0-1.1.9-2 2-2zM8 7h8a1 1 0 0 1 0 2H8a1 1 0 1 1 0-2z"
          />
          <path
            className="secondaryIcon"
            d="M11.65 18.23a4 4 0 1 1 4.7 0l2.5 3.44-2.23-.18-1.48 1.68-.59-4.2a4.04 4.04 0 0 1-1.1 0l-.6 4.2-1.47-1.68-2.23.18 2.5-3.44zM14 17a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
          />
        </svg>
        <span>Download Certificate</span>
      </button>
    </>
  );
};

export default Certificate;
