const API_URL = process.env.REACT_APP_BACKEND_URL;

async function httpGetCompany(id){
    try{
       let company = await fetch(`${API_URL}/company/${id}`);
        return company.json();
    } catch(err){
        return err;
    }
}

export {
   httpGetCompany
}