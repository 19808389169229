import styled from 'styled-components';

export const FormContainer = styled.main`
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-top: 1rem;
    overflow-y: scroll;
    /* border: 2px solid orange; */
    margin: 0 auto;
`;

export const SubmitContainer = styled.section`
    /* border: 1px solid yellow; */
    display: flex;
    justify-content: center;
    margin-block: 1rem;
    gap: 2rem;
`;
    
