import { httpGetAllUsers } from "../../Hooks/admin.hooks";
import EachComponent from "../../Components/Entity/each.component";
import { useQuery } from "@tanstack/react-query";

const UsersTab = () => {
    const {data, error, isLoading} = useQuery({
        queryKey: ['companies'],
        queryFn: () =>  httpGetAllUsers(),
        retry: 5
    })
    if(error){
        return <h3>An error occured getting the courses</h3>
    }
    if(isLoading){
        return <h3>Loading...</h3>
    }
    return (  
        <section>
                <h2>Users:</h2>
                {data?.map((c) => {
                    return <EachComponent key={c?._id} name={c?.firstName + ' ' + c?.lastName} route={`/admin/edit-user/${c?._id}`}/>
                } )}
            </section>
    );
}
 
export default UsersTab;