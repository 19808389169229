const DisplayPhoto = ({photos}) => {
    return ( 
        <>
       {photos &&  <div style={{display: 'flex', gap: '1rem', marginTop: '1rem'}}>
            <img style={{borderRadius: '5px'}} src={photos} alt="images uploaded" height="150px" width="200px" />
        </div>}
        </>
     );
}
 
export default DisplayPhoto;