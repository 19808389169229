import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { httpGetCompany } from "../../../Hooks/company.hooks";
import { FormContainer, SubmitContainer } from "../../CreateRoutes/CreateForm.styles";
import Modal from "../../../Components/Modal/modal.component";
import InputDiv from "../../FormElements/InputText";
import { useNavigate } from "react-router-dom";
import PhotosCreate from "../../../Cloudinary/CreatePhotos/createPhotos.component";
import TextareaDiv from "../../FormElements/Textarea";
import SubmitEditCompany from "./company-edit-submit.component";
import PrimaryButton from "../../../Components/Buttons/primaryButton.component";

const EditCompany = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [company, setCompany]= useState([]);
    const [successState, setSuccessState] = useState('');

    useEffect(() => {
        const GetCompany = async() => {
            let foundCompany = await httpGetCompany(id);
            if(foundCompany){
                setCompany(foundCompany[0]);
            }
        }
        GetCompany();
    }, [id]);

    return (
        <FormContainer>
        <h1>Create a company</h1>
        <hr />
        <InputDiv title="Company Name" value={company.companyName} name="companyName" nestedValue={setCompany} upperValue={company} />
        <InputDiv title="Main Contact" value={company.mainContact} name="mainContact" nestedValue={setCompany} upperValue={company} />
        <InputDiv title="Secondary Contact" value={company.secondaryContact} name="secondaryContact" nestedValue={setCompany} upperValue={company} />
        <InputDiv title="Office Phone Number" value={company.phoneOffice} name="phoneOffice" nestedValue={setCompany} upperValue={company} />
        <InputDiv title="Mobile Phone Number" value={company.phoneMobile} name="phoneMobile" nestedValue={setCompany} upperValue={company} />
        <InputDiv title="Website" value={company.website} name="website" nestedValue={setCompany} upperValue={company} />
        <InputDiv title="Address" value={company.address} name="address" nestedValue={setCompany} upperValue={company} />
        <TextareaDiv title="Notes" value={company.notes} setValue={setCompany} />
        <div style={{display: 'flex', gap: '1.5rem', alignItems: 'center', marginBlock: '1rem'}}>
        <label>Company Logo</label>
        <PhotosCreate images={company.image} setImage={setCompany} buttonLabel="Company Logo"/>
        </div>
        {isOpen && (
        <Modal onClose={() => setIsOpen(false)}>
        <p className="success">{successState}</p>
        <PrimaryButton functionName={() => navigate('/admin')} span="Go Home" />
         </Modal>
        )}
        <SubmitContainer>
        <SubmitEditCompany company={company} setIsOpen={setIsOpen} setSuccessState={setSuccessState}/>
        </SubmitContainer>
        </FormContainer>
      );
}
 
export default EditCompany;