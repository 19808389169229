import { EachAnswer } from "../Completed/quiz-completed.styles";

const DisplayAnswersEach = ({ answer, corrrectAnswer }) => {
  return (
    <EachAnswer>
      {answer === corrrectAnswer ? (
        <p className="correct">{answer}</p>
      ) : (
        <p style={{ padding: "0.5rem 1rem" }}>{answer}</p>
      )}
    </EachAnswer>
  );
};

export default DisplayAnswersEach;
