import PrimaryButton from "../../../Components/Buttons/primaryButton.component";
import { formatNum } from "../../../Helpers/formatPhoneNumber";
const SubmitEditCompany = ({company, setSuccessState, setIsOpen}) => {

    const editCompany = async(e) => {
        e.preventDefault();
        const {phoneMobile, phoneOffice} = company;
        const companyPhone = await formatNum(phoneOffice);
        const mobilephone = await formatNum(phoneMobile);
        const newCompany = Object.assign(company, {
            // employees: [],
            // active: true,
            // notes: notes,
            // // logo: image,
            // phoneMobile: mobilephone,
            // phoneOffice: companyPhone
        })
        //let res = await 
        // if(res.status === 201){
        //     setSuccessState('Company has been created');
        //     setIsOpen(true)
        // } else {
        //     setSuccessState('An error has occured, the company has not been created');
        //     setIsOpen(true)
        // }
    }

    return (  
        <>
        <PrimaryButton functionName={editCompany} span="Edit Company" />
        </>
    );
}
 
export default SubmitEditCompany;