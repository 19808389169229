import { UserSettingsMain, UserSettingsSection, InputSection, TitleSection, BottomButtonOuter } from "./userSettings.styles";
import { ButtonContainer } from "../../Quiz/quizStyles.styles";
import {useNavigate} from 'react-router-dom';
import InputDiv from "../../AdminRoutes/FormElements/InputText";
import Auth from '../../Helpers/auth';
import { useState } from "react";
import PrimaryButton from "../../Components/Buttons/primaryButton.component";
import SecondaryButton from "../../Components/Buttons/secondaryButton.component";

const UserSettings = () => {
    const navigate = useNavigate();
    const {data} = Auth.getProfile();
    const [user, setUser] = useState(data);

    return ( 
       <UserSettingsMain>
        <h2>Update your profile</h2>
        <UserSettingsSection>
            {/* For first name and last name */}
            <TitleSection>
            <h3>Name</h3>
            <span className="italicSpan">The name displayed here is what will appear as on your certificate</span>
            </TitleSection>
            <InputSection>
            <InputDiv title="First Name" value={user.firstName} name="firstName" nestedValue={setUser} upperValue={user}/>
            <InputDiv title="Last Name" value={user.lastName} name="lastName" nestedValue={setUser} upperValue={user}/>
            </InputSection>

        </UserSettingsSection>
        <UserSettingsSection>
            {/* For Email and password */}
            <TitleSection>
                <h3>Login Details</h3>
            </TitleSection>
            <InputSection>
            <InputDiv title="Email" value={user.email} name="email" nestedValue={setUser} upperValue={user}/>
            
            <InputDiv title="Password" value={user.password} name="password" nestedValue={setUser} upperValue={user}/>
            </InputSection>
        </UserSettingsSection>
        <UserSettingsSection>
             <TitleSection>
            <h4>Delete your account</h4>
            <span className="italicSpan">This action cannot be undone and is permanent</span>
            </TitleSection>
            <InputSection>
            <button onClick={(e) => {
            e.preventDefault();
            navigate()
        }} className="destructiveButton boxShadow"><span>Delete Account</span></button>
            </InputSection>
            {/* For Deleting the account */}
           
        </UserSettingsSection>
        <BottomButtonOuter>
        {/* For the bottom 2 button */}
        <ButtonContainer>
        <SecondaryButton functionName={(e) => {
            e.preventDefault();
            navigate(`/user`)
        }} span="Cancel" />
        <PrimaryButton functionName={(e) => {
            e.preventDefault();
            navigate('/user')
        }} span="Update Profile" />
        </ButtonContainer>
        </BottomButtonOuter>
       </UserSettingsMain>
     );
}
 
export default UserSettings;