import Auth from "../../Helpers/auth";
import { ButtonContainer } from "../Completed/quiz-completed.styles";
import { useNavigate } from "react-router-dom";
import Certificate from "../Certificate/certificate.component";
import SecondaryButton from "../../Components/Buttons/secondaryButton.component";

const DisplayUser = ({courseName, expiryDate, certNum, date}) => {
    const { data } = Auth.getProfile();
    const navigate = useNavigate();
    
    return ( 
        <>
           <h2 >
          Congrats!
          <br />
          You have completed 
          </h2>
     
          <h2 className="courseName" style={{textTransform: 'uppercase'}}>{courseName}</h2>
        <ButtonContainer>
          <SecondaryButton functionName={() => navigate("/user")} span="Go Home" />
          <Certificate
            name={data.firstName + " " + data.lastName}
            course={courseName}
            dateCompleted={date}
            expiryDate={expiryDate}
            certNumber={certNum}
          />
        </ButtonContainer>
        </>
     );
}
 
export default DisplayUser;