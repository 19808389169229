import PrimaryButton from "../../../Components/Buttons/primaryButton.component";
import { httpEditCourse } from "../../../Hooks/admin.hooks";

const EditCourseSubmit = ({setSuccessState, setIsOpen}) => {

    const handleSubmit = async(e) => {
        e.preventDefault();
        //console.log('INCOMING PDF', pdf)
        const fullCourse = Object.assign({}, {
            // courseName: courseName,
            // price: price,
            // description: description,
            // tags: tags,
            // pdf: pdf,
            // notes: notes,
            // videoLinks: videoLinks,
            // documentation: documentation,
            // image: image,
            // quiz: questions,
            // expiryDate: expiryDate,
            // certificatePrefix: certificatePrefix
            // certificateCount: 0
        })
        console.log('FULL COURSE SUBMITTING', fullCourse);
        let res = await httpEditCourse(fullCourse);
        console.log(res);
        if (res.status === 201) {       
            setSuccessState('Course has been created');
            setIsOpen(true)
           } else {
            setSuccessState('An error has occured, the course has not been created');
            setIsOpen(true)
        }
    }

    return (
    <>
    <PrimaryButton functionName={(e) => handleSubmit(e)} span="Create Course" />
    </>
      );
}
 
export default EditCourseSubmit;