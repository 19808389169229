import TagSelect from "./Tags";
import TertiaryButton from "../../../Components/Buttons/tertiaryButton.component";

const TagsSelectedContainer = ({tags, setTags}) => {
    return ( 
        <>
        {tags?.map((t) => {
           return <div style={{display: "flex", gap: "2rem", justifyContent: "space-between", alignItems: "center"}}>
                <p value={t}>{t}</p> 
                <TertiaryButton functionName={(e) => {
                    e.preventDefault();
                    let filtered = tags.filter((ti => ti !== t));
                    setTags(filtered);
                }} span="Delete Tag" />
            </div>
        })}
        <TagSelect tags={tags} setTags={setTags}/>
        </>
     );
}
 
export default TagsSelectedContainer;