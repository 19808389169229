import { InputContainer } from "./FormElements.styles";

const TextareaDiv = (props) => {
    //title, placeholder, value, setValue
    return ( 
        <InputContainer>
            <label>{props.title}</label>
            <textarea type="text" placeholder={props?.placeholder} value={props.value} onChange={(e) => props.setValue(e.target.value)}/>
        </InputContainer>
     );
}
 
export default TextareaDiv;