const API_URL = process.env.REACT_APP_BACKEND_URL;

async function httpGetAllCourses(){
    try{
       let courses =  await fetch(`${API_URL}/course/courses`);
        return courses.json();
    } catch(err){
        return err;
    }
}

async function httpFilterByTag(tag){
    try{
        let courses =  await fetch(`${API_URL}/course/filterTag/${tag}`);
         return courses.json();
     } catch(err){
         return err;
     }
}
async function httpFilterBySearch(search){
    try{
        let courses =  await fetch(`${API_URL}/course/filterSearch/${search}`);
         return courses.json();
     } catch(err){
         return err;
     }
}

async function httpGetSingleCourse(id){
    try{
        let courses =  await fetch(`${API_URL}/course/${id}`);
        return courses.json();
     } catch(err){
         return err;
     }
}

async function httpBuyCourse(data){
    console.log('BUY COURSE INCOMING THINGS', data)
    return await fetch(`${API_URL}/course/buy`, {
        method: 'put',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
      }).then(res => res.json())
}

export {
    httpGetAllCourses,
    httpFilterByTag,
    httpFilterBySearch,
    httpGetSingleCourse,
    httpBuyCourse
}