import styled from 'styled-components';

export const ModalContainer = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items:center;
    width: 100%;
    height: 100%;
    z-index: 20;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: 3px solid red;
    background-color: hsla(0, 0%, 45.88235294117647%, 0.5);
  .modal {
    background-color: ${({theme}) => theme.colors.white};
    width: 80%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 2em;
    position: absolute;
    border: none;
    top: 20%;
    height: 50vh;
    overflow-y: scroll;
  }
  h3{
    text-align: center;
  }
  button{
    width: 150px;
    margin-left: 40%;
  }
  .topClose{
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: end;
    padding-top: 1rem;
    padding-right: 2rem;
  }
  /* ----------------------------------------------
 * Generated by Animista on 2024-5-6 12:24:38
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@-webkit-keyframes swing-in-top-fwd{0%{-webkit-transform:rotateX(-100deg);transform:rotateX(-100deg);-webkit-transform-origin:top;transform-origin:top;opacity:0}100%{-webkit-transform:rotateX(0deg);transform:rotateX(0deg);-webkit-transform-origin:top;transform-origin:top;opacity:1}}@keyframes swing-in-top-fwd{0%{-webkit-transform:rotateX(-100deg);transform:rotateX(-100deg);-webkit-transform-origin:top;transform-origin:top;opacity:0}100%{-webkit-transform:rotateX(0deg);transform:rotateX(0deg);-webkit-transform-origin:top;transform-origin:top;opacity:1}}


`;