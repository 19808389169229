import { useParams } from "react-router-dom";
import {
  QuizCompleteContainer,
  ResultsContainer,
  ReviewQuizContainer,
} from "./quiz-completed.styles";
import { httpGetSingleCourse } from "../../Hooks/course.hooks";
import DisplayAnswersComponent from "../DisplayAnswers/displayAns.component";
import DisplayUser from "../DisplayUserInfo/displayUser.component";
import { useQuery } from "@tanstack/react-query";

const CourseComplete = () => {
  const { id } = useParams();
  
  const { data, error, isLoading } = useQuery({
    queryKey: [id],
    queryFn: () => httpGetSingleCourse(id),
    enabled: !!id,
    retry: 5,
  });

  if(error){
    return <h3>An error has occured</h3>
  }
  if(isLoading){
    return <h3>Loading...</h3>
  }

  return (
    <QuizCompleteContainer>
      <ResultsContainer>
        <DisplayUser courseName={data?.courseName} expiryDate={data?.expiryDate} certNum={data?.certNum} date={data.dateCompleted} />
      </ResultsContainer>
      <ReviewQuizContainer>
        <h2>Review Quiz</h2>
        <hr />
        {data?.quiz?.map((question, idx) => {
          return (
            <DisplayAnswersComponent question={question} idx={idx} />
          );
        })}
      </ReviewQuizContainer>
    </QuizCompleteContainer>
  );
};

export default CourseComplete;
