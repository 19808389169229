import styled from 'styled-components';

export const SingleCourseContainer = styled.main`
    height: 100vh;
    width: 100%;
    overflow: scroll;
`;
export const BreadcrumbContainer = styled.section`
    margin-left: 2rem;
`;
export const SingleCardContainer = styled.section`
    border-radius: 5px;
    margin: 1rem;
    height: 90vh;
    @media screen and (width <= 1500px){
       flex-direction: column;
       margin: 0.5rem;
       padding-bottom: 1rem;
    }

`;

export const SingleTop = styled.section`
    display: flex;
    gap: 1rem;
    width: 100%;
    padding: 1rem;
    gap: 2%;
    @media screen and (width <= 1000px){
       flex-direction: column;
    }
`;

export const SingleCourseImage = styled.aside`
    width: 40%;
    img{
        height: auto;
        max-height: 600px;
        width: 500px;
        width: 100%;
        border-radius: 5px;
    }
    @media screen and (width <= 1000px){
       width: 100%;
    }
`;

export const SingleCourseInfo = styled.aside`
    width: 60%;
    h1{
        color: ${({theme}) => theme.colors.grey6};
        font-weight: bold;
        text-transform: uppercase;
    }
    h2{
        color: ${({theme}) => theme.colors.grey4};
        font-weight: bold;
    }
    h4, h5{
        color: ${({theme}) => theme.colors.grey5};
        padding-bottom: 0.5rem;
        font-weight: 800;
    }
   
    span, p, li{
        color: ${({theme}) => theme.colors.grey3};
        font-size: 1.2rem;
        padding-bottom: 0.2rem;
    }
    p, ul{
        padding-bottom: 2rem;
    }
    button span{
        color: ${({theme}) => theme.colors.grey5};
    }
    .industries{
        display: flex;
        flex-direction: column;
        background-color: ${({theme}) => theme.colors.offWhite};
        padding: 1rem;
        border-radius: 5px;
        margin-bottom: 1rem;
    }
    .courseTop{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid ${({theme}) => theme.colors.grey1};
        padding-bottom: 0.5rem;
        margin-bottom: 1rem;
    }

    @media screen and (width <= 1000px){
        width: 100%;
        margin-top: 1rem;
    }
`;
export const SingleBottom = styled.section`
//border: 1px solid green;
    margin-left: 1rem;
    margin-top: 2rem;
    width: 90%;
    h3{
        border-bottom: 1px solid ${({theme}) => theme.colors.grey5};
        padding-bottom: 0.5rem;
        margin-bottom: 1rem;
        color: ${({theme}) => theme.colors.grey5};
    }
    p{
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
`;

export const SingleButton = styled.div`
    display: flex;
    align-items: end;
    justify-content: end;
    margin-top: 5rem;
    //border: 1px solid green;

`;

