import { useState } from "react";
import InputDiv from "../../FormElements/InputText";
import CreateUserSubmit from "./user-create-submit";
import Modal from "../../../Components/Modal/modal.component";
import { useNavigate } from "react-router-dom";
import { FormContainer, SubmitContainer } from "../CreateForm.styles";
import PrimaryButton from "../../../Components/Buttons/primaryButton.component";

const CreateUser = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
    });
    const [isOpen, setIsOpen] = useState(false);
    const [successState, setSuccessState] = useState('');

    return (  
        <FormContainer>
        <h1>Create User</h1>
        <hr />
        <InputDiv title="First Name" value={user.firstName} name="firstName" nestedValue={setUser} upperValue={user} />
        <InputDiv title="Last Name" value={user.lastName} name="lastName" nestedValue={setUser} upperValue={user} />
        <InputDiv title="Email" value={user.email} name="email" nestedValue={setUser} upperValue={user} />
        <InputDiv title="Password" value={user.password} name="password" nestedValue={setUser} upperValue={user} />
        {/* Map through the companies and choose one */}
        {/* For now it's just going to be a type in */}
        {/* <InputDiv title="Company" value={user.company} name="company" nestedValue={setUser} upperValue={user} /> */}
        {isOpen && (
        <Modal onClose={() => setIsOpen(false)}>
        <p className="success">{successState}</p>
        <PrimaryButton functionName={() => navigate('/admin')} span="Go Home" />
         </Modal>
        )}
        <SubmitContainer>
        <CreateUserSubmit user={user} setIsOpen={setIsOpen} setSuccessState={setSuccessState}/>
        </SubmitContainer>
       
        </FormContainer>
    );
}
 
export default CreateUser;