import EachComponent from "../../Components/Entity/each.component";
import { useQuery } from "@tanstack/react-query";
import { httpGetAllCourses } from "../../Hooks/course.hooks";

const CoursesTab = () => {
    const {data, error, isLoading} = useQuery({
        queryKey: ['courses'],
        queryFn: () =>  httpGetAllCourses(),
        retry: 5
    })
    if(error){
        return <h3>An error occured getting the courses</h3>
    }
    if(isLoading){
        return <h3>Loading...</h3>
    }
    return ( 
        <section>
                <h2>Courses:</h2>
                {data?.map((c) => {
                    return <EachComponent key={c?._id} name={c?.courseName} route={`/admin/edit-course/${c?._id}`} />
                } )} 
            </section>
     );
}
 
export default CoursesTab;